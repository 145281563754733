$primary: #0a0e17;
$primary-light: #30dc74;
$secondary: #8139ff;
$secundary-light: #ff00ff;

$grey: #f1f1f1;
$grey-dark: #444;

// COLORES NUEVOS
$azul-oscuro: #0a0e17;
$naranjo: #ff8a00;
$verde: #30dc74;
$rojo: #ff005c;
$fucsia: #ff00ff;
$morado: #8139ff;

// $container-max-widths: (
//   sm: 540px,
//   md: 720px,
//   lg: 960px,
//   xl: 1080px,
//   xxl: 1080px,
// );

@import "./fonts";
@import "./mixins";

@import "~bootstrap/scss/bootstrap.scss";

html,
body {
  font-family: "Poppins", "Gotham", Arial, Helvetica, sans-serif;
  font-size: 15px;
  color: #222;
}

small {
  font-size: 70%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
  color: $primary;
}
.btn {
  font-weight: 300;
  border-radius: 1.5em;
  padding: 0.7em 1.5em;
  text-transform: uppercase;

  &.btn-primary {
    background-color: $primary;
    color: white;
  }
  &.btn-marketplace {
    background-color: $secondary;
    color: white;
    &:hover {
      background-color: lighten($secondary, 5%);
      color: white;
    }
  }
}

.separador {
  display: block;
  width: 100%;
  margin: 3rem 0;
  padding-top: 1rem;
  background-image: url(./../assets/img-separador.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 35vw;
}

.preloader-overlay {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $primary;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preloader-spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-top-color: #fff;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/** 
UTILS
*/
.text-uppercase {
  text-transform: uppercase;
}

.data-head {
  color: $primary;
  font-weight: 300;
  font-size: 1.5em;
}

.data-description {
  font-size: 0.9em;
}
