@import "../../styles/base.scss";

.Validation {
  background-color: $azul-oscuro;
  margin-bottom: -4rem;
  color: white;

  .validation-header {
    padding: 5rem 0 2rem;
    text-align: center;
    img {
      width: 150px;
      height: auto;
      max-width: 70%;
      margin-bottom: 2rem;
    }
    h1 {
      padding: 0 15vw;
      text-align: center;
      color: $verde;
      font-size: 5rem;
    }

    @media screen and (max-width: 767px) {
      padding-top: 3rem;
      img {
        width: 100px;
      }
      h1 {
        font-size: 3rem;
      }
    }
  }

  .validation-description {
    text-align: center;
    font-weight: 500;
    padding: 1rem 15vw;
    span {
      color: $fucsia;
    }
  }

  .validation-data {
    h3 {
      color: white;
      font-size: 2rem;
      font-weight: 600;
    }

    a {
      color: $blue;
    }

    dl {
      dt {
        color: $naranjo;
      }

      dd {
        color: white;
        margin-bottom: 1rem;
        overflow-wrap: break-word;
      }
    }
  }

  .validation-cta {
    padding: 2rem;
    text-align: center;
    .btn {
      background-color: $verde;
      color: black;
      font-weight: 700;
    }
  }
}
