.Slideshow {
  .slider {
    background-color: black;

    .slide-item {
      position: relative;

      img {
        width: 100%;
        height: 50vh;
        object-fit: cover;
        opacity: .5;
      }

      .slide-content {
        width: 100%;
        position: absolute;
        bottom: 4.5em;

        .heading {
          width: 45vw;
          font-size: 2.5rem;
          font-weight: 300;
          color: white;
        }
      }
    }
  }

}