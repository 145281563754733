@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Bold-Regular.eot');
    src: url('../fonts/Gotham-Bold-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gotham-Bold-Regular.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Medium.eot');
    src: url('../fonts/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gotham-Medium.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Light.eot');
        src: url('../fonts/Gotham-Light.eot?#iefix') format('embedded-opentype'),
            url('../fonts/Gotham-Light.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Light.eot');
    src: url('../fonts/Gotham-Light.eot#iefix') format('embedded-opentype'),
        url('../fonts/Gotham-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url("../fonts/Poppins-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url("../fonts/Poppins-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url("../fonts/Poppins-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url("../fonts/Poppins-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url("../fonts/Poppins-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url("../fonts/Poppins-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}