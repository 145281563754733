@import "../../styles/base.scss";

.EnergyOrder {
  @include rounded-card;

  h3 {
    margin: 0;
    color: $primary;
    font-weight: 300;
    font-size: 1.3rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  p {
    font-size: 0.9em;
    font-weight: 300;
  }

  .company-image {
    margin-bottom: 1em;
    border-radius: 0.5em;
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  .company-data {
    margin-top: 1em;

    .data {
      color: $primary;
      font-weight: 300;
      font-size: 1.3em;
    }

    .data-title {
      font-size: 0.8em;
      font-weight: 300;
    }
  }
}
