.Profile {
  .two-to-one-container {
    width: 100%;
    padding-top: 50%;
    position: relative;
    overflow: hidden;
  }

  .two-to-one-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .one-to-one-container {
    width: 100%;
    padding-top: 100%;
    position: relative;
    overflow: hidden;
  }

  .one-to-one-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .description-text {
    line-height: 1.3rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 8;
    max-height: calc(1.3rem * 8);
  }
}
