@import "./styles/base.scss";

.App {
  .buscador {
    margin: 4rem 0;
    padding: 3rem 2rem;
    background-color: $grey;
  }

  .logotipos {
    margin: 4rem 0;
  }
}
.separador {
  display: block;

  .separador-heading {
    padding: 0.5rem 1rem;
    text-align: center;
    font-weight: 300;
    text-transform: uppercase;
    color: $primary;
    font-size: 1.4rem;
  }

  .iconos {
    display: block;
    margin: 0 auto;
  }
}

.site-footer {
  display: block;
  margin: 3rem 0 0;
  padding: 2rem 0;
  background-color: $primary;

  .footer-info {
    color: white;
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    font-weight: 500;
  }
}

.bg-icono-paneles {
  background-image: url(./assets/icono-paneles-solares.svg);
  background-repeat: no-repeat;
}

.bg-icono-casa {
  background-image: url(./assets/icono-casa-solar.svg);
  background-repeat: no-repeat;
}

.bg-icono-tierra {
  background-image: url(./assets/icono-tierra.svg);
  background-repeat: no-repeat;
}

.text-justify {
  text-align: justify;
}
