@import "../../styles/base.scss";

.SiteNavbar {
  .navbar {
    .navbar-brand {
      margin: 8px 16px;
    }

    .navbar-toggler {
      background-color: white;
    }

    .navbar-nav {
      .nav-link {
        font-family: "Poppins", Arial, Helvetica, sans-serif;
        color: white;
        font-weight: 600;
        font-size: 14px;

        &.active {
          color: $verde;
        }
        &:hover,
        &:active,
        &:focus {
          color: darken(white, 30%);
        }
      }
    }
  }
}
