.Wallet {
  padding: 1.5rem 1.5rem;
  max-width: 500px;
  height: auto;
  min-height: 250px;
  background-image: url(../../assets/img-fondo-wallet.jpg);
  background-position: center;
  background-size: 100% 100%;
  font-size: 0.8rem;
  color: white;

  .wallet-logo {
    margin-bottom: 2rem;
  }

  h4 {
    font-size: 0.9em;
    color: white;
    font-weight: 500;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  p {
    font-size: 1.5em;
    font-weight: 300;
  }
  .wallet-id p {
    font-size: 1.4em;
  }
}
