@import "../../styles/base.scss";

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.Home {
  .video-datos {
    position: relative;
    margin-top: -3rem;
    display: flex;
    flex-wrap: wrap;
    z-index: 2;

    .video {
      @include rounded-card;

      flex: 0 0 calc(40% - 5px);
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      @media (max-width: 767px) {
        flex: 0 0 100%;
        margin-right: 0;
        margin-bottom: 10px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
      }

      .HomeVimeoVideo {
        width: 100%;
        height: 100%;
      }
    }

    .datos {
      flex: 0 0 calc(60% - 5px);
      display: flex;
      flex-direction: column;

      @media (max-width: 767px) {
        flex: 0 0 100%;
        margin-left: 0;
      }

      .box {
        @include rounded-card;
        flex: 1;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      h2 {
        margin: 0 0 1em 0;
        font-size: 1rem;
        font-weight: 600;
        color: $primary;
      }

      .grafico-container {
        position: relative;
        width: 100%;
        height: 150px;
      }

      .col:first-child .dato {
        padding-left: 0;
        border: none;
      }

      .dato {
        background-position: top right;
        background-size: auto 50px;
        font-size: 1rem;
        border-left: 1px solid $grey;
        padding-left: 0.7em;

        .dato-titulo {
          margin: 0 0 1rem 0;
          padding: 1rem 50% 0 0;
          color: black;
          font-size: 0.8em;
          line-height: 1.1em;
          font-weight: 300;
        }

        .dato-valor {
          display: block;
          text-align: right;
          font-size: 1.5em;
          font-weight: 300;
          color: $secondary;

          .dato-unidad {
            font-size: 0.6em;
          }
        }
      }
    }
  }

  .articles {
    margin: 4rem 0;
    padding: 2rem 0;
    background-color: $grey;

    a {
      text-decoration: none;
    }

    .primary {
      img {
        width: 100%;
        height: auto;
      }

      h2 {
        font-weight: 600;
        color: $primary;
      }

      p {
        font-weight: 300;
      }
    }

    .secondary {
      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        margin-bottom: 1em;
      }

      h2 {
        font-weight: 600;
        color: $primary;
        font-size: 1em;
      }

      p {
        font-weight: 300;
        font-size: 0.8em;
      }
    }
  }

  .logotipos {
    background-color: $azul-oscuro;
    padding-bottom: 4rem;
    margin-bottom: -4rem;
  }

  .HomeCarousel {
    a:link {
      text-decoration: none;
    }
  }
}
