@import '../../styles/base.scss';

.SearchBox {
  a {
    text-decoration: none;
  }
  h2 {
    margin: 0 0 1em 0;
    font-weight: 300;
    font-size: 1.2rem;
    text-transform: uppercase;
    color: $primary;
  }
  
  .filters {
    margin: 0 0 2em 0;
    font-size: .9rem;
    
    label {
      text-transform: uppercase;
    }
    
    .form-check {
      label {
        font-weight: 300;
      }
    }
    
  }
  
  .result-orders {
    a {
      color: #333;
    }
  }
}

.radio-vertical-center input {
  margin-top: 18px;
}